import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    user: [],
    token: "",
    role: "",
    error: "",
    username: "",
    password: "",
  }),
  getters: {},
  actions: {
    sendLogin() {
      axios
        .post("/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.token = response.data.token;
          this.user = response.data.user;

          if (response.data.data.token) {
            axios.defaults.headers.common["Authorization"] =
              `Bearer ` + this.token;
          }
          router.push("/");
        })
        .catch((error) => {
          this.error = error.response.data.message;
        });
    },
    logout() {
      try {
        this.token = null;
        this.user = [];
        localStorage.clear();
      } catch (error) {
        console.error(error);
      }
    },
  },
  persist: true,
});
