<template>
  <q-card class="my-card">
    <q-card-section>
      <div class="row">
        <div class="col q-ma-sm">
          <q-input
            filled
            v-model="StopCard.form.date"
            label="Date"
            type="date"
            stack-label
          />
        </div>
        <div class="col q-ma-sm">
          <q-input
            filled
            v-model="StopCard.form.report_by"
            label="Report By"
            stack-label
          />
        </div>
        <div class="col q-ma-sm">
          <q-select
            filled
            v-model="StopCard.form.location"
            label="Lokasi"
            :options="location_opt"
            style="width: 150px"
            class="bg-white full-width"
            emit-value
            map-options
          />
        </div>
      </div>
      <div class="row">
        <div class="col q-ma-sm">
          <q-input
            filled
            v-model="StopCard.form.description"
            label="Description"
            type="textarea"
            stack-label
          />
        </div>
        <div class="col q-ma-sm">
          <q-input
            filled
            v-model="StopCard.form.recomendation"
            label="Recomendation"
            type="textarea"
            stack-label
          />
        </div>
      </div>
      <div class="row">
        <div class="col q-ma-sm">
          <q-select
            filled
            v-model="StopCard.form.stat"
            label="Status"
            :options="stat_opt"
            style="width: 150px"
            class="bg-white full-width"
            emit-value
            map-options
          />
        </div>
        <div class="col q-ma-sm">
          <q-input
            filled
            v-model="StopCard.form.target_date"
            label="Target Date"
            stack-label
          />
        </div>
        <div class="col q-ma-sm">
          <q-select
            filled
            v-model="StopCard.form.category"
            label="Category"
            :options="category_opt"
            style="width: 150px"
            class="bg-white full-width"
            emit-value
            map-options
          />
        </div>
        <div class="col q-ma-sm">
          <q-select
            filled
            v-model="StopCard.form.keterangan"
            label="Keterangan"
            :options="keterangan_opt"
            style="width: 150px"
            class="bg-white full-width"
            emit-value
            map-options
          />
        </div>
      </div>
    </q-card-section>
  </q-card>

  <q-btn color="primary q-mt-md" label="Simpan" @click="sendData" />
</template>
<script setup>
import { useStopCardStore } from "@/stores/stopCardStore";
import { useRouter } from "vue-router";
import axios from "axios";

const StopCard = useStopCardStore();
const router = useRouter();
const sendData = () => {
  axios
    .post("/stop-card/add", StopCard.form)
    .then((response) => {
      if (response.data) {
        router.push("/stop-card");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};
</script>
<script>
export default {
  data() {
    return {
      stat_opt: [
        {
          label: "Closed",
          value: "1",
        },
        {
          label: "Open",
          value: "0",
        },
      ],
      location_opt: [
        {
          label: "Sejati",
          value: "sejati",
        },
        {
          label: "Lestari",
          value: "lestari",
        },
      ],
      category_opt: [
        {
          label: "-",
          value: "-",
        },
        {
          label: "Unsafe Act",
          value: "Unsafe Act",
        },
        {
          label: "Unsafe Condition",
          value: "Unsafe Condition",
        },
        {
          label: "Safe Act",
          value: "Safe Act",
        },
      ],
      keterangan_opt: [
        "-",
        "Prosedur tidak ada",
        "Prosedur ada tapi tidak memadai",
        "Prosedur tidak di ikuti",
        "Prosedur Tidak dimengerti",
        "Standar kebersihan tidak di ketahui",
        "Standar Kebersihan tidak di ikuti",
        "Kebisingan",
        "Bahan Kimia",
        "Pencahayaan",
        "Temperatur Extrim ( Panas/dingin)",
        "Cuaca Extrim ( angin, Ombak)",
        "Bagian yang bergerak tidak dilindungi",
        "Perawatan tidak memadai",
        "Listrik",
        "Tidak Menggunakan PPE",
        "Menggunakan PPE Tidak benar",
        "Tidak Sesuai",
        "Tidak Layak Pakai",
        "Salah menggunakan",
        "Digunakan oleh orang yang tidak berwenang",
      ],
    };
  },
};
</script>
<style></style>
