import script from "./NosuratView.vue?vue&type=script&setup=true&lang=js"
export * from "./NosuratView.vue?vue&type=script&setup=true&lang=js"

import "./NosuratView.vue?vue&type=style&index=0&id=71593197&lang=sass"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QBtnGroup,QBtn,QInput,QIcon,QTd,QDialog,QCard,QCardSection,QSeparator,QSelect});
