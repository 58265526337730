<template lang="">
  <div id="printMe">
    <q-table
      :title="'Boat Inspection ' + data.boat_name"
      :rows-per-page-options="[0]"
      :rows="list_item"
      :columns="columns"
      :filter="filter"
      wrap-cells="true"
      row-key="id"
      class="my-sticky-header-table"
      dense
    >
      <template v-slot:top-row>
        <q-tr>
          <q-td colspan="100%">
            <table class="no-strip" style="width: 100%">
              <tr>
                <td><b>Tanggal</b></td>
                <td>: {{ moment(data.date).format("dddd, DD MMM YYYY") }}</td>
              </tr>
              <tr>
                <td><b>Diketahui</b></td>
                <td>: {{ data.acknowledged }}</td>
              </tr>
              <tr>
                <td><b>Inspect Person</b></td>
                <td>: {{ data.inspector }}</td>
              </tr>
              <tr>
                <td><b>Boat Name</b></td>
                <td>: {{ data.boat_name }}</td>
              </tr>
              <tr>
                <td><b>PIC</b></td>
                <td>: {{ data.pic }}</td>
              </tr>
              <tr>
                <td><b>Recomendation</b></td>
                <td>: {{ data.recomendation }}</td>
              </tr>
            </table>
          </q-td>
        </q-tr>
      </template>
      <template v-slot:top-right>
        <q-btn-group class="bg-white text-dark q-mr-md" push>
          <q-btn push label="Print" icon="print" v-print="'#printMe'" />
          <q-btn
            push
            label="Edit"
            icon="edit"
            @click="edit(this.$route.params.id)"
          />
        </q-btn-group>
        <q-input
          borderless
          dense
          v-model="filter"
          placeholder="Cari"
          text-color="white"
          class="q-mr-md q-px-md bg-white"
        >
          <template v-slot:append>
            <q-icon name="search" color="black" />
          </template>
        </q-input>
      </template>
      <template v-slot:body-cell-check="props">
        <q-td :props="props">
          <div
            :class="
              props.row.check == 1
                ? 'bg-green'
                : props.row.check == 2
                ? 'bg-red'
                : props.row.check == 3
                ? 'bg-red'
                : 'bg-white'
            "
            v-html="
              props.row.check == 1
                ? 'BAIK'
                : props.row.check == 2
                ? 'RUSAK'
                : props.row.check == 3
                ? 'TIDAK ADA'
                : 'N/A'
            "
          ></div>
        </q-td>
      </template>
      <template v-slot:body-cell-description="props">
        <q-td :props="props">
          <div v-html="props.row.finding"></div>
        </q-td>
      </template>
      <template v-slot:body-cell-attachment="props">
        <q-td :props="props">
          <a
            :href="'https://api.telukbajau.net/uploads/' + props.row.file_1"
            target="_blank"
          >
            <img
              :src="'https://api.telukbajau.net/uploads/' + props.row.file_1"
              alt=""
              srcset=""
              class="attachment-img"
            />
          </a>
        </q-td> </template
    ></q-table>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      model: "",
      check: "",
      filter: "",
      data: "",
      list_item: [],
      columns: [
        {
          name: "category",
          required: true,
          label: "Category",
          align: "left",
          field: (row) => row.item_category,
          sortable: true,
          style: "width: 100px",
        },
        {
          name: "name",
          required: true,
          label: "Item Name",
          align: "left",
          field: (row) => row.item_name,
          sortable: true,
          style: "width: 250px",
        },
        {
          name: "check",
          label: "Check",
          align: "left",
          field: "",
          style: "width: 60px",
          class: "text-center",
        },
        {
          name: "description",
          label: "Description",
          align: "left",
          style: "min-width: 150px",
          field: "",
        },
        {
          name: "attachment",
          label: "Attachment",
          align: "left",
          field: "",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/inspection/list-item/" + this.$route.params.id)
        .then((response) => {
          this.list_item = response.data.list;
          this.data = response.data.recom;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendData() {
      axios
        .post("/inspection/edit", this.form)
        .then(() => {})
        .catch(function (error) {
          console.log(error);
        });
    },
    moment: function (tgl) {
      return moment(tgl);
    },
    edit(id) {
      this.$router.push("/inspection/edit/" + id);
    },
  },
};
</script>
<style scoped>
.attachment-img {
  max-height: 150px;
}
</style>
