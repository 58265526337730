<template lang="">
  <q-table
    title="Absensi"
    dense
    :rows-per-page-options="[0]"
    :rows="absensi"
    :columns="columns"
    :filter="filter"
    :loading="loading"
    row-key="id"
    class="my-sticky-header-table"
    @row-click="onRowClick"
  >
    <template v-slot:top-right>
      <div class="row">
        <q-btn-group class="bg-white text-dark q-mr-md" push>
          <q-btn
            push
            label="Refresh"
            icon="refresh"
            @click="loadDataAbsen(tanggalAbsensi)"
          />
        </q-btn-group>
        <div class="col">
          <q-input
            borderless
            dense
            debounce="300"
            v-model="tanggalAbsensi"
            stack-label
            type="date"
            @change="loadDataAbsen(tanggalAbsensi)"
            class="q-mr-md q-px-md bg-white"
          />
        </div>
        <div class="col">
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Search"
            class="q-px-md bg-white"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </div>
    </template>
    <template v-slot:body-cell-masuk="props">
      <q-td :props="props">
        <div v-html="props.row.masuk"></div>
      </q-td>
    </template>
    <template v-slot:body-cell-pulang="props">
      <q-td :props="props">
        <div v-html="props.row.pulang"></div>
      </q-td>
    </template>
  </q-table>
</template>
<script>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      absensi: [],
      filter: "",
      loading: false,
      tanggalAbsensi: moment().format("YYYY-MM-DD"),
      columns: [
        {
          name: "tanggal",
          align: "left",
          label: "Tanggal",
          field: (row) => this.moment(row.tanggal).format("dddd, DD MMM YYYY"),
          sortable: true,
          style: "width: 60px",
        },
        {
          name: "name",
          required: true,
          label: "Nama",
          align: "left",
          field: (row) => row.pegawai_nama,
          sortable: true,
          style: "width: 150px",
        },
        {
          name: "masuk",
          label: "Masuk",
          field: "",
          style: "width: 40px",
        },
        {
          name: "pulang",
          label: "Pulang",
          field: "",
          style: "width: 40px",
        },
        {
          name: "catatan",
          align: "left",
          label: "Catatan",
          field: (row) => row.catatan,
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("dataAbsensi")) {
      this.absensi = JSON.parse(localStorage.getItem("dataAbsensi")).data;
      this.tanggalAbsensi = JSON.parse(
        localStorage.getItem("dataAbsensi")
      ).tanggal;
    } else {
      this.loadDataAbsen(this.tanggalAbsensi);
    }
  },
  methods: {
    loadDataAbsen(param) {
      axios
        .post("/absensi/get", {
          date: param,
        })
        .then((response) => {
          if (response.data) {
            localStorage.setItem("dataAbsensi", JSON.stringify(response.data));
            this.absensi = JSON.parse(localStorage.getItem("dataAbsensi")).data;
          }
        });
    },
    moment: function (tgl) {
      return moment(tgl);
    },
    notNull: function (ctt) {
      if (ctt !== null) {
        return ctt;
      } else {
        return "";
      }
    },
    onRowClick(evt, row) {
      this.$router.push("/absensi/" + row.pegawai_pin);
    },
  },
};
</script>
<style lang=""></style>
