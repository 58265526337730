<template lang="">
  <q-table
    title="Edit Boat Inspection"
    :rows-per-page-options="[0]"
    dense
    :rows="list_item"
    :columns="columns"
    :filter="filter"
    row-key="id"
    class="my-sticky-header-table"
  >
    <template v-slot:top-right>
      <div class="fit row justify-end items-end content-end">
        <q-input
          borderless
          dense
          v-model="filter"
          placeholder="Cari"
          text-color="white"
          class="q-mr-md q-px-md bg-white"
        >
          <template v-slot:append>
            <q-icon name="search" color="black" />
          </template>
        </q-input>
      </div>
    </template>
    <template v-slot:body-cell-check="props">
      <q-td :props="props">
        <select
          v-model="props.row.check"
          :class="
            props.row.check == 1
              ? 'bg-green'
              : props.row.check == 2
              ? 'bg-red'
              : props.row.check == 3
              ? 'bg-red'
              : 'bg-white'
          "
          style="width: 120px"
          name="check"
          @change="onChange($event, props.row.id)"
        >
          <option value="0">N/A</option>
          <option value="1">BAIK</option>
          <option value="2">RUSAK</option>
          <option value="3">TIDAK ADA</option>
        </select>
      </q-td>
    </template>
    <template v-slot:body-cell-description="props">
      <q-td :props="props">
        <input
          v-model="props.row.finding"
          @blur="onBlur($event, props.row.id)"
          type="text"
          style="width: 100%"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-file="props">
      <q-td :props="props">
        <div v-if="props.row.file_1">
          <a
            :href="'https://api.telukbajau.net/uploads/' + props.row.file_1"
            target="_blank"
          >
            <button>View File</button>
          </a>
        </div>
        <div v-else>
          <input
            type="file"
            class="form-control"
            ref="file"
            v-on:change="onFileChange"
          />
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-upload="props">
      <q-td :props="props">
        <div v-if="props.row.file_1">
          <button @click="deleteFile(props.row.id)">Delete</button>
        </div>
        <div v-else>
          <button @click="uploadFile(props.row.id)">Upload</button>
        </div>
      </q-td>
    </template></q-table
  >
</template>
<script>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      model: "",
      data: "",
      check: "",
      filter: "",
      file: "",
      list_item: [],
      form: {
        file: "",
        name: "",
      },
      columns: [
        {
          name: "category",
          required: true,
          label: "Category",
          align: "left",
          field: (row) => row.item_category,
          sortable: true,
          style: "width: 50px",
        },
        {
          name: "name",
          required: true,
          label: "Item Name",
          align: "left",
          field: (row) => row.item_name,
          sortable: true,
          style: "width: 150px",
        },
        {
          name: "check",
          label: "Check",
          align: "left",
          field: "",
          style: "width: 150px",
        },
        {
          name: "description",
          label: "Description",
          sortable: true,
          align: "left",
          field: "",
        },
        {
          name: "file",
          label: "Attachment",
          field: "",
          align: "left",
          style: "width: 50px",
          class: "bg-green",
        },
        {
          name: "upload",
          label: "Upload",
          field: "",
          style: "width: 50px",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/inspection/list-item/" + this.$route.params.id)
        .then((response) => {
          this.list_item = response.data.list;
          this.data = response.data.recom;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendData() {
      axios
        .post("/inspection/edit", this.form)
        .then()
        .catch(function (error) {
          console.log(error);
        });
    },
    onChange(event, id) {
      axios
        .post("/inspection/edit", {
          check: event.target.value,
          item_id: id,
          inspect_id: this.$route.params.id,
        })
        .then(() => {
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onBlur(event, id) {
      axios
        .post("/inspection/finding", {
          finding: event.target.value,
          item_id: id,
          inspect_id: this.$route.params.id,
        })
        .then(() => {
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    uploadFile(id) {
      axios
        .post(
          "/inspection/close-file",
          {
            file: this.file,
            inspect_id: this.$route.params.id,
            item_id: id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang=""></style>
