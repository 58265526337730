<template>
  <div>
    <q-table
      title="Nomor Surat"
      dense
      :rows-per-page-options="[25, 35]"
      :rows="Nosurat.rows"
      :columns="columns"
      :filter="filter"
      row-key="index"
      class="my-sticky-header-table"
      :pagination="Nosurat.rows"
      v-model:pagination.sync="Nosurat.currentPage"
    >
      <template v-slot:top-right>
        <q-btn-group class="bg-white text-dark q-mr-md" push>
          <q-btn push label="Tambah" icon="fas fa-edit" @click="openModal()" />
        </q-btn-group>
        <q-input
          borderless
          dense
          v-model="filter"
          placeholder="Cari"
          text-color="white"
          class="q-mr-md q-px-md bg-white"
        >
          <template v-slot:append>
            <q-icon name="search" color="black" />
          </template>
        </q-input>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props">
          <button @click="hapusData(props.row.id)">Hapus</button>
        </q-td>
      </template></q-table
    >
    <q-dialog v-model="modal">
      <q-card class="my-card" style="width: 800px; max-width: 80vw">
        <q-card-section>
          <div class="text-h6">Tambah Nomor Surat</div>
        </q-card-section>

        <q-separator inset />
        <q-card-section>
          <div class="row">
            <div class="col q-ma-sm">
              <q-select
                v-model="Nosurat.form.jenis_surat"
                label="Jenis Surat"
                :options="jenis_surats"
                option-label="name"
                option-value="value"
                class="bg-white q-mt-md"
                emit-value
                map-options
                required
                @update:model-value="Nosurat.getLastNoSurat"
              />
            </div>
          </div>

          <div class="row">
            <div class="col q-ma-sm">
              <q-input
                v-model="Nosurat.form.nomor"
                label="Nomor"
                stack-label
                readonly
              />
            </div>
            <div class="col q-ma-sm">
              <q-input
                v-model="Nosurat.form.tanggal"
                label="Tanggal"
                stack-label
                type="date"
              />
            </div>
          </div>
          <div class="row">
            <div class="col q-ma-sm">
              <q-input
                v-model="Nosurat.form.kepada"
                label="Kepada"
                stack-label
              />
            </div>
            <div class="col q-ma-sm">
              <q-input v-model="Nosurat.form.hal" label="Hal" stack-label />
            </div>
          </div>
          <q-btn
            color="primary q-mt-md"
            label="Simpan"
            @click.prevent="
              Nosurat.sendData();
              modal = false;
            "
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import moment from "moment/dist/moment.js";
import { useNoSuratStore } from "@/stores/noSuratStore";
import "moment/dist/locale/id";
moment.locale("id");

const Nosurat = useNoSuratStore();

const filter = "";
const modal = ref(false);

const jenis_surats = [
  {
    name: "01 - Surat Keputusan (SK)",
    value: 1,
  },
  {
    name: "02 - Surat Undangan (SU)",
    value: 2,
  },
  {
    name: "03 - Surat Permohonan (SPm)",
    value: 3,
  },
  {
    name: "04 - Surat Pemberitahuan (SPb)",
    value: 4,
  },
  {
    name: "05 - Surat Peminjaman (SPn)",
    value: 5,
  },
  {
    name: "06 - Surat Kuasa (SKa)",
    value: 6,
  },
  {
    name: "07 - Surat Tugas (ST)",
    value: 7,
  },
  {
    name: "08 - Surat Keterangan (SKet)",
    value: 8,
  },
  {
    name: "09 - Surat Rekomendasi (SR)",
    value: 9,
  },
  {
    name: "10 - Surat Balasan (SB)",
    value: 10,
  },
  {
    name: "11 - Surat Perintah Perjalanan Dinas (SPPD)",
    value: 11,
  },
  {
    name: "12 - Sertifikat (SRT)",
    value: 12,
  },
  {
    name: "13 - Perjanjian Kerja (PK)",
    value: 13,
  },
  {
    name: "14 - Surat Pengantar (SPeng)",
    value: 14,
  },
  {
    name: "15 - Surat Peringatan (SP)",
    value: 15,
  },
  {
    name: "16 - Surat Perjanjian Kerjasama (PKs)",
    value: 16,
  },
];

const columns = [
  {
    name: "index",
    label: "#",
    field: "index",
  },
  {
    name: "name",
    required: true,
    label: "No",
    align: "left",
    field: (row) => row.nomor,
    sortable: true,
  },
  {
    name: "tanggal",
    align: "left",
    label: "Tanggal",
    field: (row) => moment(row.tanggal).format("dddd, DD MMM YYYY"),
    sortable: true,
  },
  {
    name: "kepada",
    align: "left",
    label: "Kepada",
    field: (row) => row.kepada,
    sortable: true,
  },
  {
    name: "hal",
    align: "left",
    label: "Hal",
    field: (row) => row.hal,
    sortable: true,
  },
  {
    name: "action",
    label: "Action",
    field: "",
  },
];

onMounted(() => {
  Nosurat.getData();

  Nosurat.rows.forEach((row, index) => {
    row.index = index;
  });
});

function openModal() {
  modal.value = true;
  Nosurat.form.nomor = "";
  Nosurat.form.jenis_surat = "";
}

function hapusData(id) {
  if (confirm("Hapus data?")) {
    axios
      .get("/nosurat/delete/" + id)
      .then(() => {
        Nosurat.getData();
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    alert("Canceled");
  }
}
</script>
<style lang="sass">
.my-sticky-header-table
  /* height or max-height is important */
  height: 90vh


  thead tr th
    position: sticky
    background-color: white
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
