import { defineStore } from "pinia";

export const useAbsensiStore = defineStore("absensiStore", {
  state: () => ({
    data: [],
    data_rekap: [],
    month: "",
  }),
  getters: {},
  actions: {},
  persist: true,
});
