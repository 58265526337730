import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";
import print from "vue3-print-nb";
import JsonCSV from "vue-json-csv";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// axios.defaults.baseURL = "http://127.0.0.1:8000";
axios.defaults.baseURL = "https://api.telukbajau.net";

import "./assets/main.css";

let app = createApp(App);

app.use(router);
app.use(pinia);
app.component("downloadCsv", JsonCSV);
app.use(Quasar, quasarUserOptions);
app.use(print);
app.mount("#app");
