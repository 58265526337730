import { defineStore } from "pinia";
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");

export const useNoSuratStore = defineStore("noSuratStore", {
  state: () => ({
    rows: [],
    currentPage: 1,
    form: {
      nomor: "",
      jenis_surat: "",
      tanggal: moment().format("YYYY-MM-DD"),
      kepada: "",
      hal: "",
    },
  }),
  actions: {
    async getData() {
      const response = await axios.get(`/nosurat?page=${this.currentPage}`);
      this.rows = response.data.data;
    },

    getLastNoSurat() {
      axios
        .get("/nosurat/get-last/" + this.form.jenis_surat)
        .then((response) => {
          this.form.nomor = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendData() {
      axios
        .post("/nosurat", this.form)
        .then((response) => {
          if (response.data) {
            this.getData();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  persist: false,
});
