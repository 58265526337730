<script setup>
import { useAuthStore } from "@/stores/authStore";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const navigateToHome = () => {
  router.push("/");
};

const navigateBack = () => {
  router.go(-1);
};

const Auth = useAuthStore();
</script>
<template>
  <q-layout view="lHh lpr lFf" container style="height: 100vh" class="shadow-2">
    <q-header class="q-pa-sm bg-blue-10 no-print" elevated>
      <div class="row items-center">
        <q-btn
          class="cursor-pointer non-selectable q-mr-md bg-white text-dark"
          icon="fa fa-home"
          @click="navigateToHome()"
        />
        <q-btn
          v-if="route.path !== '/'"
          class="cursor-pointer non-selectable q-mr-md bg-yellow text-dark"
          icon="fa fa-angle-double-left"
          @click="navigateBack()"
          label=" Back"
        />
        <q-btn-group class="bg-white text-black">
          <q-btn class="cursor-pointer non-selectable">
            HRD
            <q-menu>
              <q-list dense style="min-width: 100px">
                <router-link to="/">
                  <q-item clickable v-close-popup>
                    <q-item-section>Absensi</q-item-section>
                  </q-item>
                </router-link>
                <router-link to="/nosurat">
                  <q-item clickable v-close-popup>
                    <q-item-section>Nomor Surat</q-item-section>
                  </q-item>
                </router-link>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn class="cursor-pointer non-selectable">
            Operation
            <q-menu auto-close>
              <q-list dense style="min-width: 100px">
                <router-link to="/inspection">
                  <q-item clickable>
                    <q-item-section>Inspection</q-item-section>
                  </q-item>
                </router-link>
                <router-link to="/stop-card">
                  <q-item clickable>
                    <q-item-section>Stop Card</q-item-section>
                  </q-item>
                </router-link>
              </q-list>
            </q-menu>
          </q-btn>
        </q-btn-group>
        <q-btn
          class="cursor-pointer non-selectable q-ml-auto bg-red"
          @click="Auth.logout()"
        >
          Logout
        </q-btn>
      </div>
    </q-header>

    <q-page-container>
      <q-page class="q-pa-sm">
        <router-view></router-view>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
