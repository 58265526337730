import { createRouter, createWebHistory } from "vue-router";
import AbsensiView from "../views/AbsensiView";
import AbsensiDetail from "../components/absensi/AbsensiDetail.vue";
import NosuratView from "../views/NosuratView";
import NosuratAdd from "../components/nosurat/AddSurat.vue";
import InspectionView from "../views/InspectionView.vue";
import AddInspection from "../components/inspection/AddInspection.vue";
import InspectionDetail from "../components/inspection/InspectionDetail.vue";
import StopCardView from "../views/StopCardView.vue";
import AddStopCard from "../components/stop-card/AddStopCard";
import LoginVue from "../views/LoginView";
const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginVue,
  },
  {
    path: "/",
    name: "absensi",
    component: AbsensiView,
  },
  {
    path: "/absensi/:user_id",
    name: "absensi-detail",
    component: AbsensiDetail,
  },
  {
    path: "/nosurat",
    name: "nosurat",
    component: NosuratView,
  },
  {
    path: "/nosurat/add",
    name: "nosurat-add",
    component: NosuratAdd,
  },
  {
    path: "/inspection",
    name: "inspection",
    component: InspectionView,
  },
  {
    path: "/inspection/detail/:id",
    name: "inspection-detail",
    component: InspectionDetail,
  },
  {
    path: "/inspection/edit/:id",
    name: "inspection-add",
    component: AddInspection,
  },

  {
    path: "/stop-card",
    name: "Stop Card",
    component: StopCardView,
  },

  {
    path: "/stop-card/add",
    name: "Add Stop Card",
    component: AddStopCard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
