<template>
  <div>
    <q-table
      title="Stop Card"
      dense
      :rows-per-page-options="[25, 35, 50, 100]"
      :rows="stopCard.data"
      :columns="columns"
      :filter="stopCard.filter"
      :wrap-cells="true"
      row-key="id"
      class="my-sticky-header-table"
    >
      <template v-slot:top-right>
        <q-select
          dense
          v-model="stopCard.month"
          label="Bulan"
          :options="months"
          style="width: 150px"
          class="bg-white q-mr-md q-px-md"
          emit-value
          map-options
          @update:model-value="() => onChange()"
        />
        <q-select
          dense
          v-model="stopCard.year"
          label="Tahun"
          :options="year"
          style="width: 150px"
          class="bg-white q-mr-md q-px-md"
          emit-value
          map-options
          @update:model-value="() => onChange()"
        />
        <q-select
          dense
          v-model="stopCard.ship"
          label="Ship"
          :options="ship_opt"
          style="width: 150px"
          class="bg-white q-mr-md q-px-md"
          emit-value
          map-options
          @update:model-value="() => onChange()"
        />
        <q-btn-group class="bg-white text-dark q-mr-md" push>
          <q-btn push label="Tambah" icon="fas fa-edit" @click="tambah" />
          <q-btn push>
            <download-csv :data="stopCard.data">Export</download-csv>
          </q-btn>
        </q-btn-group>
        <q-input
          borderless
          dense
          v-model="stopCard.filter"
          placeholder="Cari"
          text-color="white"
          class="q-mr-md q-px-md bg-white"
        >
          <template v-slot:append>
            <q-icon name="search" color="black" />
          </template>
        </q-input>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props">
          <button @click="hapusData(props.row.id)">Hapus</button>
        </q-td>
      </template></q-table
    >
  </div>
</template>

<script setup>
import { useStopCardStore } from "@/stores/stopCardStore";
import { onMounted } from "vue";
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");

const stopCard = useStopCardStore();

onMounted(() => {
  stopCard.getData();
});

const onChange = () => {
  stopCard.getData();
};

const hapusData = (id) => {
  if (confirm("Hapus data?")) {
    axios
      .get("/stop-card/delete/" + id)
      .then(() => {
        stopCard.getData();
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    alert("Canceled");
  }
};
</script>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      ship_opt: [
        {
          label: "All",
          value: "all",
        },
        {
          label: "Sejati",
          value: "sejati",
        },
        {
          label: "Lestari",
          value: "lestari",
        },
      ],
      columns: [
        {
          name: "tanggal",
          align: "left",
          label: "Tanggal",
          field: (row) => this.moment(row.date).format("dddd, DD MMM YYYY"),
          sortable: true,
          style: "width: 150px",
        },
        {
          name: "description",
          required: true,
          label: "Description",
          align: "left",
          field: (row) => row.description,
          sortable: true,
        },
        {
          name: "location",
          align: "left",
          label: "Location",
          field: (row) => row.location,
          style: "width: 100px",
          sortable: true,
        },
        {
          name: "report_by",
          align: "left",
          label: "Report By",
          field: (row) => row.report_by,
          sortable: true,
          style: "width: 150px",
        },
        {
          name: "recomendation",
          align: "left",
          label: "Recomendation",
          field: (row) => row.recomendation,
          sortable: true,
        },
        {
          name: "target_date",
          align: "left",
          label: "Target Date",
          field: (row) => row.target_date,
          sortable: true,
        },
        {
          name: "stat",
          align: "left",
          label: "Status",
          field: (row) => (row.stat == 1 ? "Closed" : "Open"),
          sortable: true,
        },
        {
          name: "category",
          align: "left",
          label: "Category",
          field: (row) => row.category,
          style: "width: 150px",
          sortable: true,
        },
        {
          name: "keterangan",
          align: "left",
          label: "Keterangan",
          field: (row) => row.keterangan,
          sortable: true,
        },
        {
          name: "action",
          label: "Action",
          field: "",
        },
      ],
      months: [
        {
          label: "All",
          value: "all",
        },
        {
          label: "January",
          value: "01",
        },
        {
          label: "February",
          value: "02",
        },
        {
          label: "March",
          value: "03",
        },
        {
          label: "April",
          value: "04",
        },
        {
          label: "May",
          value: "05",
        },
        {
          label: "June",
          value: "06",
        },
        {
          label: "July",
          value: "07",
        },
        {
          label: "August",
          value: "08",
        },
        {
          label: "September",
          value: "09",
        },
        {
          label: "October",
          value: "10",
        },
        {
          label: "November",
          value: "11",
        },
        {
          label: "December",
          value: "12",
        },
      ],
      year: ["2021", "2022", "2023"],
    };
  },
  watch: {
    month: function () {
      this.getData(this.month);
    },
  },
  mounted() {
    document.addEventListener("keydown", this.keyListener);
  },
  methods: {
    moment: function (tgl) {
      return moment(tgl);
    },
    tambah() {
      this.$router.push("/stop-card/add");
    },
    keyListener: function (e) {
      if (e.key === "q" && (e.ctrlKey || e.metaKey)) {
        this.tambah();
      }
    },
  },
};
</script>
