<template>
  <div id="printMe">
    <div class="row">
      <div class="col-3 q-mr-md no-print">
        <q-toolbar class="q-table__top text-white shadow-2">
          <q-toolbar-title>Rekap</q-toolbar-title>
        </q-toolbar>
        <q-card class="recap">
          <q-list separator>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.terlambat">
              <q-item-section>Terlambat</q-item-section>
              <q-item-section side
                >{{
                  Absensi.data_rekap.terlambat["terlambat"]
                }}
                hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.ijin">
              <q-item-section>Ijin</q-item-section>
              <q-item-section side
                >{{ Absensi.data_rekap.ijin["ijin"] }} hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.ijin_resmi">
              <q-item-section>Ijin Resmi</q-item-section>
              <q-item-section side
                >{{
                  Absensi.data_rekap.ijin_resmi["ijin_resmi"]
                }}
                hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.sakit">
              <q-item-section>Sakit</q-item-section>
              <q-item-section side
                >{{ Absensi.data_rekap.sakit["sakit"] }} hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.sakit_sd">
              <q-item-section>Sakit dgn Surat Dokter</q-item-section>
              <q-item-section side
                >{{
                  Absensi.data_rekap.sakit_sd["sakit_sd"]
                }}
                hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.dl">
              <q-item-section>Dinas Luar</q-item-section>
              <q-item-section side
                >{{ Absensi.data_rekap.dl["dl"] }} hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.cuti">
              <q-item-section>Cuti</q-item-section>
              <q-item-section side
                >{{ Absensi.data_rekap.cuti["cuti"] }} hari</q-item-section
              >
            </q-item>
            <q-item clickable v-ripple v-if="Absensi.data_rekap.alpa">
              <q-item-section>Alpa</q-item-section>
              <q-item-section side
                >{{ Absensi.data_rekap.alpa["alpa"] }} hari</q-item-section
              >
            </q-item>
          </q-list>
        </q-card>
      </div>
      <div class="col">
        <q-table
          title="Absensi"
          dense
          :rows-per-page-options="[0]"
          :rows="absensi"
          :columns="columns"
          :filter="filter"
          class="my-sticky-header-table no-border-radius"
        >
          <template v-slot:top-left>
            <div style="text-transform: uppercase" class="display-7">
              Absensi {{ nama_pegawai.pegawai_nama }}
            </div>
          </template>
          <template v-slot:top-right>
            <q-select
              dense
              v-model="month"
              label="Bulan"
              :options="months"
              style="width: 150px"
              class="bg-white q-mr-md q-px-md"
              emit-value
              map-options
              @update:model-value="(val) => changeMonth(val)"
            />
            <q-select
              dense
              v-model="year"
              label="Tahun"
              :options="years"
              style="width: 150px"
              class="bg-white q-mr-md q-px-md"
              emit-value
              map-options
              @update:model-value="(val) => changeMonth(val)"
            />
          </template>
          <template v-slot:body-cell-hari="props">
            <q-td :props="props">
              <div v-html="props.row.hari"></div>
            </q-td>
          </template>
          <template v-slot:body-cell-masuk="props">
            <q-td :props="props">
              <div v-html="props.row.masuk"></div>
            </q-td>
          </template>
          <template v-slot:body-cell-pulang="props">
            <q-td :props="props">
              <div v-html="props.row.pulang"></div>
            </q-td>
          </template>
          <template v-slot:body-cell-kehadiran="props">
            <q-td :props="props" class="select-print">
              <select
                v-model="props.row.stat"
                style="width: 120px"
                name="check"
                @update:model-value="(val) => onChange(props.row.id, val)"
              >
                <option value="1">-</option>
                <option value="2">IJIN</option>
                <option value="3">IJIN RESMI</option>
                <option value="4">SAKIT</option>
                <option value="5">SAKIT (SD)</option>
                <option value="6">DL</option>
                <option value="7">CUTI</option>
                <option value="0">ALPA</option>
              </select>
            </q-td>
          </template>
          <template v-slot:body-cell-action="props">
            <q-td :props="props">
              <button class="no-print" @click="prompt(props.row.id)">
                Tambah Catatan
              </button>
            </q-td>
          </template></q-table
        >
      </div>
    </div>
  </div>
  <q-dialog v-model="promptRef" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">Catatan</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input
          dense
          v-model="catatan"
          autofocus
          @keyup.enter="promptRef = false"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-primary no-print">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn @click="sendCatatan" flat label="Simpan" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script setup>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
import { useAbsensiStore } from "@/stores/absensiStore";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

moment.locale("id");
const Absensi = useAbsensiStore();
const route = useRoute();

const filter = ref("");
const absensi = ref([]);
const date = ref("");
const month = ref(moment().format("MM"));
const year = ref(moment().format("YYYY"));
const nama_pegawai = ref("");
// const kehadiran = ref(null);
const promptRef = ref(false);
const catatan = ref("");
// const kehadirans = ref([
//   {
//     label: "Hadir",
//     value: 1,
//   },
//   {
//     label: "Ijin",
//     value: 2,
//   },
//   {
//     label: "Sakit",
//     value: 3,
//   },
//   {
//     label: "Alpa",
//     value: 4,
//   },
// ]);
const years = ref([
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
]);
const months = ref([
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
]);
const columns = ref([
  {
    name: "tanggal",
    align: "right",
    label: "Tanggal",
    field: (row) => moment(row.id).format("dddd, DD MMM YYYY"),
    sortable: true,
    style: "width: 60px",
  },
  {
    name: "masuk",
    label: "Masuk",
    field: "",
    style: "width: 40px",
  },
  {
    name: "pulang",
    label: "Pulang",
    field: "",
    style: "width: 40px",
  },
  {
    name: "kehadiran",
    label: "Kehadiran",
    field: "",
    style: "width: 40px",
  },
  {
    name: "catatan",
    align: "left",
    label: "Catatan",
    field: (row) => row.catatan.text,
    style: "width: 100%",
    sortable: true,
  },

  {
    name: "action",
    label: "Action",
    field: "",
  },
]);

const changeMonth = (month) => {
  ambilDataPerId(month);
  getAbsenRekap(route.params.user_id, month);
};

onMounted(() => {
  ambilDataPerId(month.value);
  getAbsenRekap(route.params.user_id, month.value);
});

const getAbsenRekap = (id, bulan) => {
  axios
    .post("https://api.telukbajau.net/absensi/get-absen-rekap", {
      id: id,
      month: bulan,
      year: "2023",
    })
    .then((response) => {
      Absensi.data_rekap = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

const onChange = (tgl, val) => {
  axios
    .post("/absensi/addStat", {
      date: tgl,
      pin: route.params.user_id,
      stat: val,
    })
    .then(() => {
      ambilDataPerId(month.value);
      getAbsenRekap(route.params.user_id, month.value);
    });
};
const ambilDataPerId = (month) => {
  axios
    .post("/absensi/getdataperid", {
      id: route.params.user_id,
      month: month,
      year: year.value,
    })
    .then((response) => {
      absensi.value = response.data.data;
      nama_pegawai.value = response.data.nama;
    });
};

const prompt = (tanggal) => {
  promptRef.value = true;
  date.value = tanggal;
  console.log(tanggal);
};
const sendCatatan = () => {
  axios
    .post("/absensi/addCatatan", {
      date: date.value,
      pin: route.params.user_id,
      text: catatan.value,
    })
    .then(() => {
      ambilDataPerId(month.value);
    });
};
</script>
<style scoped>
@media print {
  col,
  .row,
  .col {
    margin-top: 0;
  }
  tr,
  td {
    margin: 0;
  }

  table {
    box-shadow: none;
    overflow: hidden !important;
    width: 100% !important;
    height: 100% !important;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    position: absolute !important;
    top: 0;
    left: 0;
  }

  .q-btn,
  .q-field {
    display: none;
  }
  .no-print,
  .no-print * {
    display: none !important;
    height: 0px !important;
  }

  .my-sticky-header-table {
    height: auto !important;
  }
  .q-table__card {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }

  .q-table__container > div:first-child {
    border-radius: 0 !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .select-print select {
    border: none !important;
    background-color: transparent;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
}
</style>
