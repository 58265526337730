import { defineStore } from "pinia";
import axios from "axios";

export const useStopCardStore = defineStore("stopCardStore", {
  state: () => ({
    data: [],
    filter: "",
    ship: "all",
    month: "all",
    year: "all",
    form: {
      date: "",
      report_by: "",
      location: "",
      description: "",
      recomendation: "",
      target_date: "",
      category: "",
      keterangan: "",
      stat: "",
    },
  }),
  getters: {},
  actions: {
    getData() {
      axios
        .post("/stop-card", {
          ship: this.ship,
          month: this.month,
          year: this.year,
        })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  persist: true,
});
