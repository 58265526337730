import script from "./StopCardView.vue?vue&type=script&setup=true&lang=js"
export * from "./StopCardView.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QSelect,QBtnGroup,QBtn,QInput,QIcon,QTd});
