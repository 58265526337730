<template>
  <div v-if="Auth.token">
    <MainLayout />
  </div>
  <div v-else>
    <LoginView />
  </div>
</template>
<script setup>
import MainLayout from "./components/layout/MainLayout.vue";
import LoginView from "./views/LoginView.vue";
import { useAuthStore } from "./stores/authStore";
import axios from "axios";
import { watch } from "vue";

const Auth = useAuthStore();

watch(
  () => Auth.token,
  (newvalue) => {
    if (newvalue) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + newvalue;
    }
  }
);
</script>
<style></style>
