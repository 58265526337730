<template lang="">
  <div>
    <q-card class="my-card">
      <q-card-section>
        <div class="text-h6">Tambah Nomor Surat</div>
      </q-card-section>

      <q-separator inset />
      <q-card-section>
        <div class="row">
          <div class="col q-ma-sm">
            <q-input v-model="form.nomor" label="Nomor" stack-label readonly />
          </div>
          <div class="col q-ma-sm">
            <q-input
              v-model="form.tanggal"
              label="Tanggal"
              stack-label
              type="date"
            />
          </div>
        </div>
        <div class="row">
          <div class="col q-ma-sm">
            <q-input v-model="form.kepada" label="Kepada" stack-label />
          </div>
          <div class="col q-ma-sm">
            <q-input v-model="form.hal" label="Hal" stack-label />
          </div>
        </div>
        <q-btn
          color="primary q-mt-md"
          label="Simpan"
          @click.prevent="sendData"
        />
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      form: {
        nomor: "",
        tanggal: moment().format("YYYY-MM-DD"),
        kepada: "",
        hal: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/nosurat/get-last")
        .then((response) => {
          this.form.nomor = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendData() {
      axios
        .post("/nosurat", this.form)
        .then((response) => {
          if (response.data) {
            this.$router.push("/nosurat");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang=""></style>
