<template lang="">
  <q-table
    title="Boat Inspection"
    dense
    :rows="data"
    :rows-per-page-options="[0]"
    :columns="columns"
    row-key="id"
    class="my-sticky-header-table"
    @row-click="onRowClick"
  >
    <template v-slot:top-right>
      <q-btn-group class="bg-white text-dark q-mr-md" push>
        <q-btn push label="New Inspect" icon="fas fa-edit" @click="prompt()" />
      </q-btn-group>
      <q-input
        borderless
        dense
        placeholder="Cari"
        text-color="white"
        class="q-mr-md q-px-md bg-white"
      >
        <template v-slot:append>
          <q-icon name="search" color="black" />
        </template>
      </q-input>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <button @click.stop="prompt(props.row.id)">Edit</button>
      </q-td>
    </template>
  </q-table>
  <q-dialog v-model="promptRef" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6">New Inspection</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="row">
          <div class="col q-ma-sm">
            <q-input v-model="form.boat_name" label="Boat Name" stack-label />
          </div>
          <div class="col q-ma-sm">
            <q-input
              v-model="form.date"
              label="Tanggal"
              stack-label
              type="date"
            />
          </div>
          <div class="col q-ma-sm">
            <q-select
              v-model="form.location"
              :options="locations"
              label="Lokasi"
            />
          </div>
        </div>
        <div class="row">
          <div class="col q-ma-sm">
            <q-input v-model="form.inspector" label="Inspector" stack-label />
          </div>
          <div class="col q-ma-sm">
            <q-input v-model="form.pic" label="PIC" stack-label />
          </div>
          <div class="col q-ma-sm">
            <q-input
              v-model="form.acknowledged"
              label="Diketahui"
              stack-label
            />
          </div>
        </div>
        <div class="row">
          <div class="col q-ma-sm">
            <q-input
              v-model="form.recomendation"
              label="Recomendation"
              type="textarea"
              stack-label
            />
          </div>
        </div>
        <div class="row">
          <div class="col q-ma-sm">
            <q-select
              v-model="form.stat"
              :options="status"
              emit-value
              map-options
              label="Status"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn @click="sendData" flat label="Simpan" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      data: [],
      form: {
        boat_name: "",
        date: "",
        location: "",
        inspector: "",
        acknowledged: "",
        recomendation: "",
        pic: "",
        stat: "",
      },
      promptRef: false,
      locations: ["CPU", "SPU", "NPU", "HDL", "SMD", "BPP"],
      status: [
        {
          label: "Open",
          value: 1,
        },
        {
          label: "Closed",
          value: 0,
        },
      ],
      columns: [
        {
          name: "name",
          required: true,
          label: "Nama",
          align: "left",
          field: (row) => row.boat_name,
          sortable: true,
          style: "width: 150px",
        },
        {
          name: "tanggal",
          align: "left",
          label: "Tanggal",
          field: (row) => moment(row.date).format("dddd, DD MMM YYYY"),
          sortable: true,
          style: "width: 60px",
        },
        {
          name: "lokasi",
          align: "left",
          label: "Lokasi",
          field: (row) => row.location,
          sortable: true,
          style: "width: 60px",
        },
        {
          name: "inspector",
          align: "left",
          label: "Inspect Person",
          field: (row) => row.inspector,
          sortable: true,
        },
        {
          name: "stat",
          align: "left",
          label: "Status",
          field: (row) => (row.stat == 1 ? "Open" : "Closed"),
          sortable: true,
          style: "width: 80px",
        },
        {
          name: "action",
          label: "Action",
          field: "",
          style: "width: 60px",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/inspection")
        .then((response) => {
          console.log(response);
          this.data = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendData() {
      axios
        .post("/inspection", this.form)
        .then(() => {
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    prompt(id) {
      this.promptRef = true;
      axios
        .get("/inspection/" + id)
        .then((response) => {
          this.form.boat_name = response.data.boat_name;
          this.form.date = response.data.date;
          this.form.location = response.data.location;
          this.form.inspector = response.data.inspector;
          this.form.acknowledged = response.data.acknowledged;
          this.form.recomendation = response.data.recomendation;
          this.form.pic = response.data.pic;
          this.form.stat = response.data.stat;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onRowClick(evt, row) {
      this.$router.push("/inspection/detail/" + row.id);
    },
  },
};
</script>
<style lang=""></style>
