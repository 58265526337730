<script setup>
import { useAuthStore } from "@/stores/authStore";
const Auth = useAuthStore();
</script>
<template>
  <div class="row justify-center items-center login-page">
    <div class="login-box text-center">
      <div class="q-mb-lg">
        <p>
          <b>Selamat datang di Dashboard Telukbajau.net</b> <br />Silahkan
          masukan data login Anda
        </p>
      </div>
      <q-input
        label="Username"
        color="teal"
        class="q-mb-md"
        clearable
        v-model="Auth.username"
      />
      <q-input
        type="password"
        label="Password"
        color="teal"
        class="q-mb-sm"
        v-model="Auth.password"
      >
        <template #append>
          <q-icon
            name="remove_red_eye"
            class="cursor-pointer"
            :color="showPassword ? 'teal' : 'grey'"
            @click="showPassword = !showPassword"
          />
        </template>
      </q-input>

      <q-btn
        @click="Auth.sendLogin()"
        class="q-mt-md full-width bg-primary text-white"
      >
        Login
      </q-btn>
    </div>
  </div>
</template>
<style scoped>
body {
  padding: none !important;
  margin: none !important;
}
.login-page {
  height: 100vh;
}

img {
  width: 100px;
}

.login-box {
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #f2f2f2 0%, #e2e2e2 100%);
}
</style>
